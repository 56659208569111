exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-colorado-springs-web-developer-js": () => import("./../../../src/pages/colorado-springs-web-developer.js" /* webpackChunkName: "component---src-pages-colorado-springs-web-developer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-headless-wordpress-developer-js": () => import("./../../../src/pages/headless-wordpress-developer.js" /* webpackChunkName: "component---src-pages-headless-wordpress-developer-js" */),
  "component---src-pages-html-sitemap-js": () => import("./../../../src/pages/html-sitemap.js" /* webpackChunkName: "component---src-pages-html-sitemap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-word-press-category-js": () => import("./../../../src/templates/wordPressCategory.js" /* webpackChunkName: "component---src-templates-word-press-category-js" */),
  "component---src-templates-word-press-post-js": () => import("./../../../src/templates/wordPressPost.js" /* webpackChunkName: "component---src-templates-word-press-post-js" */),
  "slice---src-components-footer-footer-js": () => import("./../../../src/components/Footer/Footer.js" /* webpackChunkName: "slice---src-components-footer-footer-js" */),
  "slice---src-components-header-header-js": () => import("./../../../src/components/Header/Header.js" /* webpackChunkName: "slice---src-components-header-header-js" */)
}

